import React, { useState } from 'react'
import Loader from '../../layout/Loader'
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';

const Signup = () => {

  const navigate = useNavigate();


  const [loading, setLoading] = useState(false);


  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmitHandle = async (e) => {

    let name = e.name
    let emailId = e.email
    let companyName = e.company
    let jobTitle = e.job
    let phoneNumber = e.phone
    let password = e.password


    setLoading(true)

    try {
      const res = await axios.post('https://api.jarsis.ai/user/createUser', { emailId, password, companyName, phoneNumber, jobTitle, name })
      console.log(res.data);
      if (res.data.success === true) {
        localStorage.setItem("usertoken", res.data.payload.token)
        navigate('/home')
      }
      setLoading(false)

    } catch (error) {
      console.log(error);
      setLoading(false)
      toast.error(error.response.data.message)

    }
  }


  return (
    <>

      <div hidden={!loading}>
        <Loader />
      </div>

      <main className='tw-mx-auto tw-w-[40%] tw-my-20'>
        <div className="text-center mb-7">
          <h3 className="text-body-highlight">Sign Up</h3>
          <p className="text-body-tertiary">Create your account today</p>
        </div>

        <Form onSubmit={handleSubmit(onSubmitHandle)}>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="name">Name</Form.Label>
            <Form.Control
              id="name"
              type="text"
              placeholder="Name"
              autoComplete='off'
              {...register('name', {
                required: '*Name is required'
              })} />

            {errors.name && <p className='tw-text-red-500 tw-mt-2'>{errors.name.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              id="email"
              type="email"
              placeholder="name@example.com"
              autoComplete='off'
              {...register('email', {
                required: '*Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address',
                },
              })} />

            {errors.email && <p className='tw-text-red-500 tw-mt-2'>{errors.email.message}</p>}

          </Form.Group>


          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="company">Company Name</Form.Label>
            <Form.Control id="company" type="text" placeholder="Company Name" autoComplete='off' {...register('company', {
              required: '*Company Name is required'
            })} />

            {errors.company && <p className='tw-text-red-500 tw-mt-2'>{errors.company.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="job">Designation</Form.Label>
            <Form.Control id="job" type="text" placeholder="Designation" autoComplete='off'  {...register('job', {
              required: '*Designation is required'
            })} />

            {errors.job && <p className='tw-text-red-500 tw-mt-2'>{errors.job.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="phone">Mobile Number</Form.Label>
            <Form.Control id="phone" type="number" autoComplete='off' placeholder="Mobile Number without +" {...register('phone', {
              required: '*Mobile Number is required',
              minLength: {
                value: 10,
                message: '*Mobile Number must be at least 10 Number',
              },
              maxLength: {
                value: 10,
                message: '*Mobile Number cannot exceed more than 10 Number',
              },
            })} />

            {errors.phone && <p className='tw-text-red-500 tw-mt-2'>{errors.phone.message}</p>}
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="password">Password</Form.Label>
            <Form.Control id="password" type="password" autoComplete='off' placeholder="password"
              {...register('password', {
                required: '*Password is required',
                minLength: {
                  value: 4,
                  message: '*Password must be at least 4 characters',
                },
                maxLength: {
                  value: 14,
                  message: '*Password cannot exceed more than 14 characters',
                },
              })}
            />

            {errors.password && <p className='tw-text-red-500 tw-mt-2'>{errors.password.message}</p>}
          </Form.Group>

          <Button disabled={loading} variant="primary" type='submit' className="w-100 mb-3" >
            Sign up
          </Button>

          <div className="text-center">
            <Link
              to={`/`}
              className="fs-9 fw-bold"
            >
              Sign in to an existing account
            </Link>
          </div>
        </Form>
      </main>
    </>
  )
}

export default Signup