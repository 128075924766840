import React, { useState, useRef } from "react";
const {
  TranscribeStreamingClient,
  StartStreamTranscriptionCommand,
} = require("@aws-sdk/client-transcribe-streaming");

// Interview component
function Interview() {
  const controllerRef = useRef(null); // For controlling the readable stream
  const readableStream = useRef(null); // Holds the readable stream
  const audioContextRef = useRef(null); // For managing the AudioContext
  const sourceRef = useRef(null); // Reference to the MediaStreamAudioSourceNode
  const processorNodeRef = useRef(null); // Reference to the AudioWorkletNode

  const LanguageCode = "en-US";
  const MediaEncoding = "pcm";
  const MediaSampleRateHertz = "16000";
  const credentials = {
    accessKeyId: "AKIAVUZYWFBLSOJY7EG4",
    secretAccessKey: "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",
  };

  const [transcript, setTranscript] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const client = new TranscribeStreamingClient({
    region: "ap-south-1",
    credentials,
  });

  readableStream.current = new ReadableStream({
    start(controller) {
      // Save a reference to the controller so we can enqueue from outside
      controllerRef.current = controller;
    },
  });

  // Define the async generator function
  const micStream = async function* () {
    const reader = readableStream.current.getReader();
    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break; // Exit when the stream is done
        yield { AudioEvent: { AudioChunk: value } }; // Yield formatted chunk
      }
    } catch (error) {
      console.error("Error reading stream:", error);
    } finally {
      reader.releaseLock(); // Release the reader lock when done
    }
  };

  const params = {
    LanguageCode,
    MediaEncoding,
    MediaSampleRateHertz,
    AudioStream: (async function* () {
      for await (const audioEvent of micStream()) {
        yield audioEvent;
      }
    })(),
  };

  const command = new StartStreamTranscriptionCommand(params);

  // Start transcription
  const startTranscription = async () => {
    const response = await client.send(command);
    try {
      for await (const event of response.TranscriptResultStream) {
        if (event.TranscriptEvent) {
          const results = event.TranscriptEvent.Transcript.Results;
          if (results.length > 0 && !results[0].IsPartial) {
            const newTranscript = results[0].Alternatives[0].Transcript;
            console.log("New transcript chunk:", newTranscript);
            setTranscript((prev) => (prev || "") + newTranscript + " ");
          }
        }
      }
    } catch (err) {
      console.error("Error during transcription:", err);
    }
  };

  // Helper function to convert Float32Array to PCM
  const float32ToPCM = (input) => {
    const buffer = new ArrayBuffer(input.length * 2);
    const view = new DataView(buffer);
    for (let i = 0; i < input.length; i++) {
      let s = Math.max(-1, Math.min(1, input[i]));
      view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }
    return new Uint8Array(buffer);
  };

  startTranscription();
  // Start interview (recording)
  const startInterview = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(async (stream) => {
      audioContextRef.current = new AudioContext({ sampleRate: 16000 });
      sourceRef.current = new MediaStreamAudioSourceNode(audioContextRef.current, {
        mediaStream: stream,
      });

      try {
        await audioContextRef.current.audioWorklet.addModule("./processor.js");
        processorNodeRef.current = new AudioWorkletNode(audioContextRef.current, "mic-audio-processor");

        processorNodeRef.current.port.onmessage = (e) => {
          const audioChunk = new Float32Array(e.data);
          const pcmData = float32ToPCM(audioChunk);
          controllerRef.current.enqueue(pcmData);
        };

        sourceRef.current.connect(processorNodeRef.current);
        setIsRecording(true);

      } catch (error) {
        console.error(error);
      }
    });
  };

  // Stop interview (stop recording)
  const stopInterview = () => {
    if (audioContextRef.current && controllerRef.current) {
      // Close the audio stream and terminate transcription
      audioContextRef.current.close();
      controllerRef.current.close();
      setIsRecording(false);
    }
  };

  return (
    <>

      <div className="p-2 flex justify-center">
        <div className="flex mt-8">
          <button type="submit" className="bg-gray-500 text-white p-2 rounded text-sm w-20" onClick={startInterview} disabled={isRecording}>
            Start
          </button>
          <button type="submit" className="bg-yellow-500 text-white p-2 ml-6 rounded text-lg w-20" onClick={stopInterview} disabled={!isRecording}>
            Stop
          </button>
        </div>
      </div>
      <div className="mt-14 mx-auto p-20">
        <textarea id="message" rows="4" value={transcript ? transcript : ""} disabled className="block h-[500px] p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="transcript here..."></textarea>
      </div>

    </>
  );
}

export default Interview;
