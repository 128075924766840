import React from 'react'
import viewdetail from '../../img/viewdetail.png'
import blue from '../../img/blue.png'
import red from '../../img/red.png'
import yellow from '../../img/yellow.png'

const ViewDetails = () => {
    return (

        <main className='tw-bg-[#0036c2] tw-text-white tw-pb-20 tw-px-10 tw-pt-10 poppins'>

            <p className='tw-font-bold tw-text-2xl'>Personal Details</p>

            <section className=' tw-bg-[#002fa8] tw-flex tw-justify-between tw-p-5 tw-mt-10 tw-w-[60%] tw-rounded-lg'>
                {/* table  */}
                <main>
                    <div className='tw-flex'>
                        <p className='tw-w-24'>Name</p>
                        <p className='tw-w-10'>:</p>
                        <p>G.jebastin</p>
                    </div>
                    <div className='tw-flex tw-mt-5'>
                        <p className='tw-w-24'>Start Time </p>
                        <p className='tw-w-10'>:</p>
                        <p>03.30 pm</p>
                    </div>

                    <div className='tw-flex tw-mt-5'>
                        <p className='tw-w-24'>Email </p>
                        <p className='tw-w-10'>:</p>
                        <p>jebastin@gmail.com</p>
                    </div>

                    <div className='tw-flex tw-mt-5'>
                        <p className='tw-w-24'>Date </p>
                        <p className='tw-w-10'>:</p>
                        <p> 12/07/2024</p>
                    </div>

                    <div className='tw-flex tw-mt-5'>
                        <p className='tw-w-24'>Duration </p>
                        <p className='tw-w-10'>:</p>
                        <p>30 mins</p>
                    </div>
                </main>
                <main>
                    <img src={viewdetail} alt="" />
                </main>

            </section>

            <p className='tw-font-bold tw-text-xl tw-ml-5 tw-mt-10'>Sentiment Analysis</p>

            <section className='tw-flex tw-items-center tw-gap-x-52 tw-ml-10 tw-pt-10'>
                <main className='tw-flex tw-flex-col tw-items-center'>
                    <img src={blue} alt="" className='tw-w-24' />
                    <p className='tw-mt-2'>75% <span className='tw-text-[#17D0DA]'>Neutral</span></p>
                </main>

                <main className='tw-flex tw-flex-col tw-items-center'>
                    <img src={red} alt="" className='tw-w-24' />
                    <p>95% <span className='tw-text-[#F20408]'>Negative</span></p>
                </main>

                <main className='tw-flex tw-flex-col tw-items-center'>
                    <img src={yellow} alt="" className='tw-w-24' />
                    <p>50% <span className='tw-text-[#CDE324]'>Positive</span></p>
                </main>
            </section>


            <p className='tw-mx-auto tw-border-white tw-border-2 tw-w-fit tw-mt-28 tw-px-14 tw-py-2 tw-rounded-xl tw-bg-[#002992]'>Text Summer</p>

        </main>
    )
}

export default ViewDetails