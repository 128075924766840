import "./App.css";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./components/home/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import Interview from "./components/interview/Interview";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewDetails from "./components/viewDetails/ViewDetails";
import Dashboard from "./components/dashboard/Dashboard";
function App() {
  const AuthGuard = (props) => {
    const auth = (localStorage.getItem('usertoken') != null) ? true : null;

    // If has token, return outlet in other case return navigate to login page

    return auth ? props.Component : <Navigate to="/" />;
  }

  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/home" element={<AuthGuard Component={<Home />} />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/viewDetails" element={<ViewDetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
