import React, { useEffect, useState } from 'react'
import Loader from '../../layout/Loader'
import axios from 'axios';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify';
import Select from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

const Home = () => {

  const { register, control, handleSubmit, unregister, reset, setValue, formState: { errors } } = useForm();

  const navigate = useNavigate();

  // showing interview questions and Candidate Interviews
  const [showingInterviewQuestions, setShowingInterviewQuestions] = useState(true);
  const [showingCandidateInterviews, setShowingCandidateInterviews] = useState(false);
  const [showInterviewList, setshowInterviewList] = useState(false);
  const [candidateRoles, setCandidateRoles] = useState([]);
  const [interviewList, setInterviewList] = useState([]);
  const [showanalysis, setshowAnalysis] = useState(false);
  const [analyzeReport, setAnalyzeReport] = useState([]);

  //  modal for interview questions 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // modal for delete pop-up
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  const handleCloseDeletePopUp = () => setShowDeletePopUp(false);
  const handleShowDeletePopUp = () => setShowDeletePopUp(true);

  // modal for Candidate Interview
  const [showCandidateInterview, setShowCandidateInterview] = useState(false);

  const handleCloseCandidateInterview = () => setShowCandidateInterview(false);
  const handleShowCandidateInterview = () => setShowCandidateInterview(true);

  // loading
  const [loading, setLoading] = useState(true);

  // creating variable for storing fetching data
  const [interviewQuestionData, setInterviewQuestionData] = useState([]);
  const [candidateInterview, setCandidateInterview] = useState([]);




  const [candidaterole, setCandidateRole] = useState('')
  const [questionUpdated, setQuestionUpdated] = useState(false);


  useEffect(() => {


  }, [showingInterviewQuestions, showingCandidateInterviews, candidateInterview])

  // fetching interview question data------------------------------------------------------------->
  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true);
        const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

        setInterviewQuestionData(res?.data?.payload?.interviewQuestionDetails);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };

    // fetchData(); // Trigger the fetching
    getInterviewRoles();
  }, []);


  useEffect(() => {
    getInterviewRoles();
  }, [questionUpdated])

  useEffect(() => {


    fetchData(); // Trigger the fetching
  }, []);


  const handleShowingInterviewQuestions = async () => {
    setShowingInterviewQuestions(!showingInterviewQuestions)
    setShowingCandidateInterviews(false)
    setshowInterviewList(false)
    await getInterviewRoles();
    await fetchData()
  }

  const getInterviewList = async () => {
    const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviews', {}, {
      headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
    })
    if (res.data.success) {
      const { payload } = res?.data;
      const { interviewDetails } = payload;
      setInterviewList(interviewDetails);
    } else {
      setInterviewList([]);
    }
  }

  const handleShowingCandidateInterviews = async (from = "") => {

    if (from === 'interviewList') {
      setShowingCandidateInterviews(false)
      setShowingInterviewQuestions(false)
      setshowInterviewList(true)
      await getInterviewList()
    } else {
      setshowInterviewList(false)
      setShowingCandidateInterviews(!showingCandidateInterviews)
      setShowingInterviewQuestions(false)
      await getInterviewRoles();
      await fetchData()

      unregister("role")
      for (let i = 0; i <= 10; i++) {
        let index = i + 1
        unregister("question" + index)
      }
    }


  }




  const getInterviewRoles = async () => {
    setLoading(true)

    try {
      const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, {
        headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
      })

      if (res.data.success === true) {
        // const roles = [];
        const payload = res.data.payload.interviewQuestionDetails;
        setInterviewQuestionData(payload);
        const roles = payload.map(data => { return { value: data?._id, label: data?.role } })
        setCandidateRoles(roles)

      }
      setLoading(false)

    } catch (error) {
      setLoading(false)
      console.log(error);

    }
  }

  const handlingAddInterviewQuestionButton = async (e) => {

    let role = e.role
    let question1 = e.question1
    let question2 = e.question2
    let question3 = e.question3
    let question4 = e.question4
    let question5 = e.question5
    let question6 = e.question6
    let question7 = e.question7
    let question8 = e.question8
    let question9 = e.question9
    let question10 = e.question10


    setLoading(true)

    try {
      const res = await axios.post('https://api.jarsis.ai/interviewQuestion/createInterviewQuestion', { role, question1, question2, question3, question4, question5, question6, question7, question8, question9, question10 }, {
        headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' }
      })
      if (res.data.success === true) {
        setShow(false);
        setQuestionUpdated(!questionUpdated)
        // const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
        // setInterviewQuestionData(res?.data?.payload?.interviewQuestionDetails);

      }
      setLoading(false)

      //window.location.reload();

    } catch (error) {
      setLoading(false)
      toast.error(error.response.data.message)

      console.log(error);

    }
  }



  const fetchData = async () => {
    try {

      setLoading(true);
      const ress = await axios.post('https://api.jarsis.ai/interview/getAllInterviews', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

      //setCandidateInterview(res?.data?.payload?.interviewDetails);

      const test = ress?.data?.payload?.interviewDetails;
      // const check = test;
      test.map(async (data, index) => {
        let candidateRole = await getrole(data?.interviewQuestions);
        test[index].candidateRole = candidateRole;
        // check[index] = candidateRole
      })

      setCandidateInterview(test);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };


  // fetching candidate interview data--------------------------------------------------------------------->




  const handlingAddCandidateInterviewButton = async (e) => {

    let candidateEmail = e.candidateEmail
    let prompt = e.prompt

    setLoading(true)

    try {
      const res = await axios.post('https://api.jarsis.ai/interview/createInterview', {
        candidateEmail, prompt, interviewQuestions: candidaterole

      }, {
        headers: { 'Authorization': `${localStorage.getItem("usertoken")}` }
      })
      if (res.data.success === true) {

        setShowCandidateInterview(false);
        setShowingInterviewQuestions(showingInterviewQuestions)

        const res = await axios.post('https://api.jarsis.ai/interview/getAllInterviews', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
        setCandidateInterview(res?.data?.payload?.interviewDetails);

      }
      setLoading(false)
      reset();
      //  window.location.reload()

    } catch (error) {
      toast.error(error.response.data.message)

      setLoading(false)

    }
  }

  const getrole = async (intId) => {
    // const res = await axios.post('https://api.jarsis.ai/interviewQuestion/getAllInterviewQuestions', {}, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })
    let roleName = "";
    //if (res.data.success === true) {
    if (interviewQuestionData.length !== 0) {
      const payload = [interviewQuestionData[0]];

      const chhh = payload.map(data => {
        if (data?._id === intId) {
          roleName = data.role
        } else {
          roleName = ""
        }
        return roleName;
      });
      let chkkk = chhh.filter(n => n)
      return chkkk[0];
    } else {
      return ""
    }

  }

  const setcadodateRolehandler = (e) => {
    setCandidateRole(e.value)
    setValue("canRole", e.label)
    delete errors.canRole
  }

  const viewAnalyzeReport = async (interviewId) => {
    try {
      setLoading(true);
      const res = await axios.post('https://api.jarsis.ai/analysis/getAnalysisByInterviewId', { "interviewId": interviewId }, { headers: { 'Authorization': `${localStorage.getItem("usertoken")}`, 'Content-Type': 'application/json' } })

      if (res.data.success) {
        setshowAnalysis(true);
        const { payload } = res?.data;
        setAnalyzeReport(payload?.result)
      } else {
        if (res.status === 350) {
          toast.error("Sorry,No Chat history")
        } else {
          toast.error("Sorry,Can't generate report");
        }
        setshowAnalysis(false);
        setAnalyzeReport([])
      }
      setLoading(false);
    } catch (e) {
      toast.error("Sorry,Can't generate report");
      setLoading(false);
      setAnalyzeReport([])
      setshowAnalysis(false);
    }
  }

  return (
    <>

      <div hidden={!loading}>
        <Loader />
      </div>

      {/* header */}
      <section className='tw-border-b-2 tw-border-black tw-pb-5 tw-flex tw-items-center tw-justify-between tw-px-20 tw-mt-10'>
        <div className='tw-font-medium tw-text-2xl'>Thanigaivel</div>
        <div className='tw-font-bold tw-text-5xl'>Home Page</div>
        <Link onClick={() => { localStorage.removeItem('usertoken'); window.location.href = '/' }} className='tw-no-underline tw-bg-blue-400 tw-text-white tw-p-3 tw-rounded-lg'>
          Log out
        </Link>



      </section>

      {/* content */}
      <section className='tw-flex tw-justify-between  tw-px-20 '>

        {/* buttons  */}
        <main className='tw-w-[20%] tw-pt-10 tw-border-r-2 tw-h-screen tw-border-black'>
          <button disabled={showingInterviewQuestions} onClick={handleShowingInterviewQuestions} className='tw-bg-blue-400 tw-text-white tw-p-3 tw-rounded-lg tw-block tw-my-5'>
            Interview Questions
          </button>

          <button disabled={showingCandidateInterviews} onClick={handleShowingCandidateInterviews} className='tw-bg-green-400 tw-text-white tw-p-3 tw-rounded-lg'>
            Candidate Interviews
          </button>


          <button disabled={showInterviewList} onClick={() => handleShowingCandidateInterviews("interviewList")} className='tw-bg-blue-400 tw-text-white tw-p-3 tw-rounded-lg tw-block tw-my-5'>
            Interviews Report
          </button>


        </main>

        <ReactTooltip
          id="sentiment-tooltip"
          className='my-tooltip'
          place="top"
          variant="info"
          content={<div>
            <p>1 : Very bad</p>
            <p>2 : Bad</p>
            <p>3 : Good</p>
            <p>4 : Excellent</p>
            <p>5 : Awesome</p>
          </div>}
        />

        <ReactTooltip
          id="emotion-tooltip"
          className='my-tooltip'
          place="top"
          variant="info"
          content={<div>
            <p>Happy</p>
            <p>Sad</p>
            <p>Angry</p>
            <p>Neutral</p>
          </div>}
        />

        <Modal show={showanalysis} onHide={() => setshowAnalysis(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Analyze Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="sentiment-tooltip">
                Sentiment Analysis <i class="fa fa-question-circle"></i>:-
              </h1>
              {analyzeReport.length !== 0 && <p class="tw-text-sm tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                {analyzeReport[0].text}
              </p>}
              <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400" data-tooltip-id="emotion-tooltip">
                Emotion Analysis <i class="fa fa-question-circle"></i>:-
              </h1>
              {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                {analyzeReport[1].text}
              </p>}
              <h1 class="tw-text-lg tw-font-normal tw-text-blue-600 lg:tw-text-xl dark:tw-text-gray-400">
                Vocabulary Words:-
              </h1>
              {analyzeReport.length !== 0 && <p class="tw-text-lg tw-font-normal tw-text-gray-500 lg:tw-text-xl dark:tw-text-gray-400">
                {analyzeReport[2].text}
              </p>}

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setshowAnalysis(false)}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>

        <main className='tw-w-[70%] tw-pt-10'>

          {/* interview question */}
          {showingInterviewQuestions && <main hidden={!showingInterviewQuestions} >

            {/*   Add Interview Questions button */}
            <section className='tw-flex tw-justify-end tw-mb-8'>

              <button onClick={handleShow} className='tw-bg-green-400 tw-text-white tw-p-3 tw-rounded-lg '>
                Add Interview Questions
              </button>

              <Modal show={show} onHide={handleClose} animation={false}>

                <Modal.Body>
                  <form action="" onSubmit={handleSubmit(handlingAddInterviewQuestionButton)}>
                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Role</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('role', {
                          required: '*Role is required'
                        })} />
                      {errors.role && <p className='tw-text-red-500 tw-mt-2'>{errors.role.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 1?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question1', {
                          required: '*Question 1 is required'
                        })} />
                      {errors.question1 && <p className='tw-text-red-500 tw-mt-2'>{errors.question1.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 2?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question2', {
                          required: '*Question 2 is required'
                        })} />
                      {errors.question2 && <p className='tw-text-red-500 tw-mt-2'>{errors.question2.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 3?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question3', {
                          required: '*Question 3 is required'
                        })} />
                      {errors.question3 && <p className='tw-text-red-500 tw-mt-2'>{errors.question3.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 4?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question4', {
                          required: '*Question 4 is required'
                        })} />
                      {errors.question4 && <p className='tw-text-red-500 tw-mt-2'>{errors.question4.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 5?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question5', {
                          required: '*Question 5 is required'
                        })} />
                      {errors.question5 && <p className='tw-text-red-500 tw-mt-2'>{errors.question5.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 6?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question6', {
                          required: '*Question 6 is required'
                        })} />
                      {errors.question6 && <p className='tw-text-red-500 tw-mt-2'>{errors.question6.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 7?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question7', {
                          required: '*Question 7 is required'
                        })} />
                      {errors.question7 && <p className='tw-text-red-500 tw-mt-2'>{errors.question7.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 8?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question8', {
                          required: '*Question 8 is required'
                        })} />
                      {errors.question8 && <p className='tw-text-red-500 tw-mt-2'>{errors.question8.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 9?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question9', {
                          required: '*Question 9 is required'
                        })} />
                      {errors.question9 && <p className='tw-text-red-500 tw-mt-2'>{errors.question9.message}</p>}
                    </div>

                    <div className='tw-my-3'>
                      <label className='tw-w-28' htmlFor="">Question 10?</label>
                      <input type="text" className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('question10', {
                          required: '*Question 10 is required'
                        })} />
                      {errors.question10 && <p className='tw-text-red-500 tw-mt-2'>{errors.question10.message}</p>}
                    </div>

                    <div className=' tw-flex tw-justify-center'>
                      <Button disabled={loading} type='submit' className='tw-mx-auto' variant="primary">Add</Button>
                    </div>
                  </form>


                </Modal.Body>

              </Modal>


            </section>

            {interviewQuestionData?.length === 0 ?
              <p className='tw-border tw-p-4 tw-rounded-lg tw-font-medium'>No Interview Questions Found</p>
              : Array.isArray(interviewQuestionData) && [...interviewQuestionData]?.reverse()?.map((data, index) => (
                <Accordion key={index} className='tw-mb-5'>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{data.role}</Accordion.Header>
                    <Accordion.Body>

                      <div className=' tw-flex tw-justify-between tw-mt-2 tw-mb-6'>
                        <Button className='' variant="secondary">Modify Ouestions</Button>
                        <Button className='' onClick={handleShowDeletePopUp} variant="danger">Delete</Button>
                      </div>

                      {/* delete popup button */}
                      <Modal show={showDeletePopUp} fullscreen='sm-down' onHide={handleCloseDeletePopUp}>

                        <Modal.Body >
                          <p className='tw-text-center tw-text-xl tw-font-medium tw-mt-5'>Are you sure want to delete</p>
                          <div className=' tw-flex tw-justify-between tw-mt-10 tw-mb-6 tw-mx-20 '>
                            <Button className='' variant="danger">Yes</Button>
                            <Button className='' onClick={handleCloseDeletePopUp} variant="secondary">No</Button>
                          </div>
                        </Modal.Body>

                      </Modal>


                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 1?</label>
                        <input readOnly type="text" value={data.question1} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 2?</label>
                        <input readOnly type="text" value={data.question2} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 3?</label>
                        <input readOnly type="text" value={data.question3} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 4?</label>
                        <input readOnly type="text" value={data.question4} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 5?</label>
                        <input readOnly type="text" value={data.question5} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 6?</label>
                        <input readOnly type="text" value={data.question6} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 7?</label>
                        <input readOnly type="text" value={data.question7} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 8?</label>
                        <input readOnly type="text" value={data.question8} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 9?</label>
                        <input readOnly type="text" value={data.question9} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-28' htmlFor="">Questioin 10?</label>
                        <input readOnly type="text" value={data.question10} className=' tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>


                      <div className=' tw-flex tw-justify-end'>
                        <Button className='' variant="primary">Update</Button>
                      </div>


                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              ))
            }


          </main>}

          {/* Candidate Interviews */}
          {showingCandidateInterviews && <main hidden={!showingCandidateInterviews} >

            {/*  Add Candidate Interview button */}
            <section className='tw-flex tw-justify-end tw-mb-8'>

              <button onClick={handleShowCandidateInterview} className='tw-bg-green-400 tw-text-white tw-p-3 tw-rounded-lg '>
                Add Candidate Interview
              </button>

              <Modal show={showCandidateInterview} size='lg' onHide={handleCloseCandidateInterview} animation={false}>
                <Modal.Body>
                  <form onSubmit={handleSubmit(handlingAddCandidateInterviewButton)} className='tw-mx-10'>

                    <div className='tw-my-3'>
                      <label className='tw-w-44' htmlFor="">Candidate Email</label>
                      <input type="text" className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' autoComplete='off'
                        {...register('candidateEmail', {
                          required: '*Candidate Email is required',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Invalid email address',
                          },
                        })} />
                      {errors.candidateEmail && <p className='tw-text-red-500 tw-mt-2 tw-w-96 tw-text-end'>{errors.candidateEmail.message}</p>}
                    </div>

                    <div className='tw-my-3 tw-flex'>
                      <label className='tw-w-44' htmlFor="">Candiate Role</label>
                      {/* <select name="" id="" className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' onChange={e => setcadodateRolehandler(e)}>
                        <option value="" hidden></option>
                        {candidateRoles.map((val, index) => {
                          return <option key={index} value={val.label}>{val.value}</option>
                        })}
                      </select> */}

                      {/* <select {...register("canRole", { required: "*Please select candidate role" })} onChange={e => setcadodateRolehandler(e)} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'>
                        <option selected="selected" value="" hidden></option>
                        {candidateRoles.map((val, index) => {
                          return <option key={index} value={val.label}>{val.value}</option>
                        })}
                      </select> */}
                      <Controller
                        name="canRole"
                        control={control}
                        rules={{ required: "*Please select candidate role" }}
                        render={({ field }) => <Select
                          {...field}
                          value={candidateRoles[candidaterole]}
                          className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                          onChange={e => setcadodateRolehandler(e)}
                          options={candidateRoles}
                        />}
                      />
                    </div>
                    {errors.canRole && <p className=' tw-text-red-500 tw-mt-2 tw-w-96 tw-text-end'>{errors.canRole.message}</p>}

                    <div className='tw-my-3 tw-flex tw-items-center'>
                      <label className='tw-w-44' htmlFor="">Prompt</label>
                      <textarea name="" id="" className=' tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4'
                        {...register('prompt', {
                          required: '*Prompt is required'
                        })} ></textarea>
                    </div>
                    {errors.prompt && <p className=' tw-text-red-500 tw-mt-2 tw-w-80 tw-text-end'>{errors.prompt.message}</p>}


                    <div className=' tw-flex tw-justify-center tw-mt-5'>
                      <Button disabled={loading} type='submit' className='tw-mx-auto' variant="primary">Schedule</Button>
                    </div>
                  </form>


                </Modal.Body>

              </Modal>


            </section>


            {candidateInterview?.length === 0 ?
              <p className='tw-border tw-p-4 tw-rounded-lg tw-font-medium'>No Candidate Interview Found</p>
              : Array.isArray(candidateInterview) && [...candidateInterview]?.reverse()?.map((data, index) => {
                return <Accordion className='tw-mb-5' key={index}>

                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{data.candidateEmail}</Accordion.Header>
                    <Accordion.Body>

                      <div className=' tw-flex tw-justify-between tw-mt-2 tw-mb-6'>
                        <Button className='' variant="secondary">Modify</Button>
                        <Button className='' onClick={handleShowDeletePopUp} variant="success">Update</Button>
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-44' htmlFor="">Role</label>
                        <input readOnly type="text" value={data.candidateRole} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      {/* <div className='tw-my-3'>
                        <label className='tw-w-44' htmlFor="">Interview Start Date</label>
                        <input readOnly type="text" value={data.interviewDate} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-44' htmlFor="">Interview Start Time</label>
                        <input readOnly type="text" value={data.interviewStartTime} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div>

                      <div className='tw-my-3'>
                        <label className='tw-w-44' htmlFor="">Interview Duration</label>
                        <input readOnly type="text" value={data.interviewDuration} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' />
                      </div> */}

                      <div className='tw-my-3 tw-flex tw-items-center'>
                        <label className='tw-w-44' htmlFor="">Prompt</label>
                        <textarea readOnly name="" id="" value={data.prompt} className='tw-w-60 tw-outline-none tw-border tw-bg-slate-100 tw-rounded-md tw-px-3 tw-py-1 tw-mx-4' ></textarea>
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              })
            }
          </main>}
          {showInterviewList && <main hidden={!showInterviewList} >
            {/* <section className='tw-flex tw-justify-end tw-mb-8'>
            </section> */}
            <div className=''>
              <div class="tw-relative tw-overflow-x-auto">
                <table class="tw-w-full tw-text-sm tw-text-left tw-rtl:text-right tw-text-gray-500 dark:tw-text-gray-400">
                  <thead class="tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50 dark:tw-bg-gray-700 dark:tw-text-gray-400">
                    <tr>
                      <th scope="col" class="tw-px-6 tw-py-3">
                        Email
                      </th>
                      <th scope="col" class="tw-px-6 tw-py-3">
                        Report
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {interviewList.map((value, index) => {
                      return <tr class="tw-bg-white tw-border-b dark:tw-bg-gray-800 dark:tw-border-gray-700">
                        <th scope="row" class="tw-px-6 tw-py-4 tw-font-medium tw-text-gray-900 tw-whitespace-nowrap dark:tw-text-white">
                          {value?.candidateEmail}
                        </th>
                        <td class="tw-px-6 tw-py-4">
                          {value.messages.length !== 0 || value.reports.length !== 0 ? <a className='tw-text-blue-400' href='javascript:void(0);' onClick={() => viewAnalyzeReport(value?._id)}>View Analysis</a> :
                            <p className='tw-text-black'>No Chat History</p>}

                        </td>
                      </tr>
                    })}

                  </tbody>
                </table>
              </div>

            </div>

          </main>}
        </main>
      </section>
    </>
  )


}

export default Home